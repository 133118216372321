
import VueTypes from 'vue-types'
import { UiLink, UiTitle } from '~/components/atoms'

export default {
  components: {
    UiLink,
    UiTitle,
  },
  props: {
    title: VueTypes.string.def(''),
    links: VueTypes.array.def([]),
  },
}
