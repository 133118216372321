
import VueTypes from 'vue-types'
import { Divider, Collapsable, UiLink, Icon } from '~/components/atoms'

export default {
  components: {
    Divider,
    Collapsable,
    UiLink,
    Icon,
  },
  props: {
    title: VueTypes.string.def(''),
    links: VueTypes.array.def([]),
  },
}
