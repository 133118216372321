
import { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import Mobile from './mobile.vue'
import Desktop from './desktop.vue'
import { IFooterLinks } from '~/models/components/FooterLinks'
import { IExternalLinkModel } from '~/models/base/ExternalLink'

export default {
  components: {
    Mobile,
    Desktop,
  },
  props: {
    title: {
      type: String,
      default: '',
    } as PropOptions<IFooterLinks['title']>,
    links: {
      type: Array,
      default: () => [],
    } as PropOptions<IFooterLinks['groupLinks']>,
  },
  computed: {
    ...mapGetters('context', [
      'locale',
      'marketplacePrefix',
      'isGlobalMarketplace',
    ]),
    formattedLinks(): IExternalLinkModel[] {
      return this.links.map(link => {
        const formattedLink = { ...link }
        formattedLink.url =
          formattedLink.url ||
          `/${this.marketplacePrefix}/${formattedLink.slug}`
        return formattedLink.url?.includes('help')
          ? { ...formattedLink, url: `${link.url}?locale=${this.locale}` }
          : formattedLink
      })
    },
    componentName(): string {
      return this.$mq === 'sm' ? 'mobile' : 'desktop'
    },
  },
}
